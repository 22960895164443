import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import Layout from "../components/layout/index"
import SEO from "../components/seo"
import { StyledContainerS } from "../components/containers/index"
import StyledSectionBox from "../components/sectionBox/index"

const AboutPageEn = ({
  location,
  data: { allPrismicAboutPage, main, background },
}) => {
  return (
    <Layout location={location}>
      <SEO
        title="Frameway Industries Ltd. - Transfer Parts Manufacturer, Progressive Parts Manufacturer, Multi-slide Bending Parts Manufacturer"
        lang="en-US"
      />
      <StyledSectionBox
        background={background.childImageSharp.fluid}
        title="about"
      />
      <StyledContainerS style={{ marginBottom: 64 }}>
        <Img
          fluid={main.childImageSharp.fluid}
          alt="Factory building"
          style={{ marginBottom: 24 }}
        />
        <RichText render={allPrismicAboutPage.edges[0].node.data.content.raw} />
      </StyledContainerS>
    </Layout>
  )
}

export const query = graphql`
  {
    allPrismicAboutPage(filter: { lang: { eq: "en-us" } }) {
      edges {
        node {
          data {
            content {
              raw
            }
          }
        }
      }
    }
    main: file(relativePath: { eq: "about/about-img.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 968) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    background: file(relativePath: { eq: "about/about-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default AboutPageEn
